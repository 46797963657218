<template>
  <div>
    <v-card>
      <v-card-text>
        <div
          v-if="order.amount_deposit != order.order_expense && order.PT_type === 'DP'"
          class="warning-badge" role="alert">
          <h4>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span>  &nbsp;&nbsp;ลูกค้าค้างชำระ (จ่ายเเล้ว)  {{order.amount_deposit}} บาท</span>
          </h4>
        </div>
        <div
          v-if="order.importune === 'Y'"
          class="danger-badge" role="alert">
          <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span style="color: white">  &nbsp;&nbsp;ลูกค้าต้องการสินค้าเร่งด่วน </span>
          </h4>
        </div>
        <div
          v-if="order.is_review === 'Y'"
          class="success-badge" role="alert">
          <h4>
<!--            <a href="/admin/scan">-->
<!--              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>-->
<!--              -->
<!--            </a>-->
            <router-link :to="{ name : 'pages-scan'}">
              <span>  &nbsp;&nbsp;ลูกค้าต้องการรีวิวสินค้า</span>
            </router-link>
          </h4>
        </div>
        <div
          v-if="order.PT_type === 'T' && order.additional_payment === 'Y'"
          class="warning-badge" role="alert">
          <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span>  &nbsp;&nbsp;โอนส่วนต่างค่าจัดส่ง  {{order.order_expense_oth ? order.order_expense_oth  : order.transport_expense_price }} บาท</span>
          </h4>
        </div>
        <div
          v-if="order.member_update && order.created_at && order.created_at != order.member_update"
          class="success-badge" role="alert">
          <h4><i class="fa fa-clock-o" aria-hidden="true"></i>
            <span> ลูกค้ามีการอัพเดทข้อมูลการสั่งซื้อ ณ. {{ order.member_update}}</span></h4>
        </div>
        <div
          v-if="order.status_oth"
          class="warning-badge" role="alert">
          <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span> แจ้งสถานะบิลเพิ่มเติม  {{ order.status_oth }}</span></h4>
        </div>
        <br>
        <div
          v-if="order.PT_type === 'DT'"
          class="warning-badge" role="alert">
          <h4><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span> ลูกค้าชำระเงินปลายทาง</span></h4>
        </div>
        <br>
      </v-card-text>
    </v-card>

    <v-card id="account-setting-card">
      <!-- tabs -->
      <v-tabs v-model="tab" show-arrows v-if="!$store.state.user.member_id">
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs v-model="tab" show-arrows  v-if="$store.state.user.member_id">
        <v-tab>
          <v-icon size="20" class="me-3">
            {{ icons.mdiCartOutline}}
          </v-icon>
          <span>ข้อมูลการสั่งซื้อจากลูกค้า</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <OrderDetail
            :order_id="order_id"
            :order="order"
            :url_tracking="url_tracking"
            :order_details="order_details"
          />
        </v-tab-item>

        <v-tab-item>
          <OrderReview
            :order_id="order_id"
            :order="order"/>
        </v-tab-item>

        <v-tab-item>
          <OrderAction
            :order="order"
            :order_details="order_details"
            :url_tracking="url_tracking"
            :order_id="order_id"/>
        </v-tab-item>

      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mdiCartOutline, mdiCartPlus, mdiMore } from '@mdi/js'
import { ref } from '@vue/composition-api'

import {mdiAbacus} from "@mdi/js/commonjs/mdi";
import instant_order from '@/services/order'
import OrderDetail from "@/views/pages/bill/Tabs/orderDetail";
import OrderReview from "@/views/pages/bill/Tabs/orderReview";
import OrderAction from "@/views/pages/bill/Tabs/orderAction";


export default {
  components: {
    OrderDetail,
    OrderReview,
    OrderAction
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'ข้อมูลการสั่งซื้อจากลูกค้า', icon: mdiCartOutline },
      { title: 'รูปภาพรีวิวให้ลูกค้า', icon: mdiCartPlus },
      { title: 'เครื่องมือ', icon: mdiMore },
    ]


    return {
      tab,
      tabs,
      icons: {
        mdiCartOutline,
        mdiCartPlus,
        mdiMore,
      },
    }
  },
  data: () => ({
    order_id : '',
    order : {},
    order_details : [],
    url_tracking : {}
  }),
  methods : {
    getOrderDetail() {
      this.$store.state.isLoading = true;
      instant_order.getReportBookingOrderDetail(this.order_id).then(res => {
        this.order = res.data.order;
        this.order.PT_slip = null;
        this.order_details = res.data.order_details;
        this.url_tracking = res.data.url_tracking;
        this.$store.state.isLoading = false;
      }).catch(err => {
        this.$store.state.isLoading = false;
      });
    }
    // get_order(){
    //
    // }
  },
  mounted() {
    this.order_id = this.$route.params.id;
    this.getOrderDetail();
  }
}
</script>
