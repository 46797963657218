<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="4"
    >
      <v-card>
        <v-card-title>
          ลูกค้า : {{ order.member ? order.member.member_name : '-' }}
        </v-card-title>
        <v-card-text>
          <p>ร้าน: {{ order.member ? order.member.member_shop_name : '-' }}</p>
          <p>เบอร์ติดต่อ: {{ order.member ? order.member.member_tele : '-' }}</p>
          <p>
            เลขที่: {{ order.order_inv }}
            <router-link
              v-if="order.by_claim_at"
              target="_blank"
              :to="{ name : 'pages-claim', query: { search: order.by_claim_id, claim_id: order.by_claim_id } }"
            >
              (บิลเคลม)
            </router-link>
          </p>
          <p>วันที่สั่งซื้อ: {{ order.order_date_time }} น.</p>
          <p>
            วันที่ชำระเงิน:
            <span v-if="order.PT_date && order.PT_time">
              {{ order.PT_date }} {{ order.PT_time }} น.ห
              <!--              {{substr(order.PT_date,0,10)}} {{order.PT_time}}  น.-->
            </span>
            <span v-if="!order.PT_date || !order.PT_time">-</span>
          </p>
          <p>
            สถานะการสั่งซิ้อ:
            <span v-if="order.order_status === 'PT'">รอดำเนินการโอน</span>
            <span v-if="order.order_status === 'PC'">รอการตรวจสอบ</span>
            <span v-if="order.order_status === 'PP'">อยู่ระหว่างดำเนินการผลิต</span>
            <span v-if="order.order_status === 'PTP'">เตรียมจัดส่ง/กำลังจัดส่ง</span>
            <span v-if="order.order_status === 'F'">เสร็จสิ้น</span>
            <span v-if="order.order_status === 'C'">ยกเลิก</span>
          </p>
          <p>เลขแทรค: {{ order.PP_tax_no ? order.PP_tax_no : 'ไม่พบเลขแทรค' }}</p>

          <hr>
          <br>
          <p
            style="font-size: 18px"
            class="warning-badge"
          >
            <b>ความต้องการลูกค้า</b>
          </p> <br>
          <div
            v-for="(item,index) in configs"
            :key="index"
          >
            <div v-if="item.id === 2">
              <input
                v-model="item.check"
                type="checkbox"
                disabled
                :value="item.id"
                @click="ConfigImportune()"
              >
              <span style="color: #ff4d4d"> {{ item.name }}</span>
              <!--              <input type="hidden"  v-model="importune" checked>-->
            </div>
            <div v-if="item.id === 1">
              <!--              @if(auth()->user()->member_id != 51)-->
              <input
                v-model="item.check"
                type="checkbox"
                disabled
                :value="item.id"
                @click="ConfigIsReview()"
              >
              <span style="color: #ff4d4d"> {{ item.name }} </span>
              <div v-if="item.check && item.id === 1">
                <!--                <input   type="hidden" checked v-model="is_review">-->
              </div>
            </div>
            <!--            @endif-->
            <div v-if="item.id != 1 && item.id != 2">
              <input
                v-model="item.check"
                type="checkbox"
                disabled
                :value="item.id"
              >
              <span style="color: #ff4d4d"> {{ item.name }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="4"
    >
      <v-card>
        <v-card-title>
          <span>หลักฐานการชำระเงิน</span>
          <span>
            <span>
              <span v-if="order.order_status === 'PT'">รอดำเนินการโอน</span>
              <span v-if="order.order_status === 'PC'">รอการตรวจสอบ</span>
              <span v-if="order.order_status === 'PP'">อยู่ระหว่างดำเนินการผลิต</span>
              <span v-if="order.order_status === 'PTP'">เตรียมจัดส่ง/กำลังจัดส่ง</span>
              <span v-if="order.order_status === 'F'">เสร็จสิ้น</span>
              <span v-if="order.order_status === 'C'">ยกเลิก</span>
            </span>
          </span>
          <!--                      @if($order->order_status === 'PT')-->
          <!--                      <span-->
          <!--                        class="badge badge-warning float-right">รอดำเนินการโอน</span>-->
          <!--                      @elseif($order->order_status === 'PC')-->
          <!--                      <span-->
          <!--                        class="badge badge-warning float-right">รอการตรวจสอบ</span>-->
          <!--                      @elseif($order->order_status === 'PP')-->
          <!--                      <span class="badge badge-success float-right">อยู่ระหว่างดำเนินการผลิต</span>-->
          <!--                      @elseif($order->order_status === 'PTP')-->
          <!--                      <span class="badge badge-success float-right">เตรียมจัดส่ง/กำลังจัดส่ง</span>-->
          <!--                      @elseif($order->order_status === 'F')-->
          <!--                      <span class="badge badge-success float-right">เสร็จสิ้น</span>-->
          <!--                      @elseif($order->order_status === 'C')-->
          <!--                      <span class="badge badge-danger float-right">ยกเลิก</span>-->
          <!--                      @else-->
          <!--                      - -->
          <!--                      @endif-->
        </v-card-title>
        <v-card-text>
          <p>วันที่สั่งซื้อ: {{ order.order_date_time }} น.</p>
          <p>
            การชำระเงิน:
            <span
              v-if="order.PT_type === 'C'"
              class="success-badge"
            >เงินสด</span>
            <span
              v-if="order.PT_type === 'T'"
              class="success-badge"
            >โอน</span>
            <span
              v-if="order.PT_type === 'T' && order.additional_payment === 'Y'"
              style="background-color: rgb(39, 174, 96) !important"
              class="success-badge"
            >
              เครดิตสินค้า
            </span>
            <span
              v-if="order.PT_type === 'T' && order.additional_payment === 'Y'"
              style="background-color: rgb(39, 174, 96) !important"
              class="warning-badge"
            >
              โอนส่วนต่างค่าจัดส่ง
            </span>

            <span
              v-if="!order.PT_slip && order.additional_payment === 'Y' && order.PT_date"
              class="success-badge"
            >หักจากกระเป่าเงินออนไลน์</span>
            <span
              v-if="order.PT_type === 'CD'"
              class="success-badge"
            >บัตรเครดิต</span>
            <span
              v-if="order.PT_type === 'CC'"
              class="success-badge"
            >เครดิตเงินในกระเป๋าออนไลน์</span>
            <span
              v-if="order.PT_type === 'CP'"
              class="success-badge"
            >เครดิตเสินค้า(ชิ้น)</span>
            <span
              v-if="order.PT_type === 'DP'"
              class="success-badge"
            >ลูกค้ามัดจำ</span>
          </p>
          <p class="danger-badge">
            จำนวนเงินที่ต้องชำระ: {{ order.order_expense }} บาท
          </p>

          <div v-if="order.payment_type === 'qrnone' || order.payment_type === 'card'">
            <span
              class="warning-badge"
              style="font-size: 16px"
            >จ่ายด้วยระบบ Api Payment </span>
            <span
              class="success-badge"
              style="font-size: 14px"
            >    ชำระเงินโดย {{ order.payment_type === 'qrnone' ? 'จ่ายเงินโดย QR Code' : 'บัตรเคดิต/เดบิต' }}</span>
            <span
              class="warning-badge"
              style="font-size: 14px"
            >    อ้างอิงรหัสธุรกรรม: {{ order.payment_transaction_id }}</span>
            <span
              class="danger-badge"
              style="font-size: 14px"
            >    จำนวนเงิน : {{ order.PT_price_amt }} </span>
            <span
              class="warning-badge"
              style="font-size: 14px"
            >  สถานะ: {{ order.payment_status }} </span>
            <span
              class="warning-badge"
              style="font-size: 14px"
            > เวลาที่ระบบเช็คการจ่ายเงินล่าสุด: {{ order.payment_check_at }} </span>
            <p style="font-size: 14px">
              หมายเตุ: {{ order.payment_note }}
            </p>
          </div>

          <div v-if="order.payment_type === 'installment'">
            <span
              class="warning-badge"
              style="font-size: 16px"
            >จ่ายด้วยระบบ Api Payment </span>
            <span
              class="warning-badge"
              style="font-size: 14px"
            >อ้างอิงรหัสธุรกรรม: {{ order.payment_transaction_id }}</span>
          </div>

          <div v-if="order.PT_slip">
            วันที่ชำระเงิน:    {{ (order.PT_date && order.PT_time) ? order.PT_date+' '+order.PT_time+' น.' : '-' }}
            <br>
            วันที่จัดส่ง:  {{ order.PTP_date }}  {{ order.PTP_time }} น.
            <br>
            <router-link
              target="_blank"
              :to="order.PT_slip"
            >
              <span
                style="color: red"
              >กดเพื่อดูหลักฐานการชำระเงิน</span>
            </router-link>
          </div>

          <div v-if="order.PT_type === 'T'">
            <p>{{ order.BankName }}</p>
            <p>{{ order.account_number }}</p>
            <p>{{ order.account_name }}</p>
          </div>

          <p>
            ติดตามสถานะสิ่งของ :
            <span style="color:red;">
              {{ url_tracking.transport_type_name ? url_tracking.transport_type_name : '' }}
            </span>
          </p>

          <v-textarea
            v-if="order.PP_tax_no"
            id="url_tacking"
            v-model="order.PP_tax_no"
          >
          </v-textarea>
          <!--            <br>-->
          <v-btn
            v-if="order.PP_tax_no"
            style="width: 100%"
            color="primary"
            @click="copyTrackingUrl()"
          >
            คัดลอกเลขพัสดุ
          </v-btn>

          <!--            href=" {{$order->trackingUrl($order->transport_type_id)->url ? $order->trackingUrl($order->transport_type_id)->url : ''}}"-->
          <a
            style="text-decoration: none;"
            :href="url_tracking.url_tracking"
            target="_blank"
          >
            <v-btn
              title="กดเช็คสถานะสินค้า"
              class="warning-badge mt-3"
              style="font-size: 16px;width: 100%"
            >
              &nbsp;&nbsp; กดเช็คสถานะ&nbsp;&nbsp;
            </v-btn>
          </a>

          <div v-if="order.PT_type === 'DP'">
            <hr>
            <p>
              <i
                class="fa fa-calendar-check-o"
                aria-hidden="true"
              ></i>
              ประวัติการชำระเงิน
              (ประเภทการมัดจำ)
            </p>
            <div style="font-size: 11px">
              <div
                v-if="(order.PT_type === 'DP' && order.amount_deposit != order.order_expense) && (order.amount_deposit != order.order_expense)"
                class="alert alert-success"
                role="alert"
              >
                <h6>
                  จ่ายเเล้ว {{ order.amount_deposit }} บาท
                </h6>
              </div>
              <div
                class="alert alert-warning"
                role="alert"
              >
                <h6>
                  ยอดคงเหลือที่ต้องชำระ
                  {{ order.order_expense - order.amount_deposit }} บาท
                </h6>
              </div>
              <div v-for="(deposit,index) in order.deposits">
                <hr>
                <span>{{ index + 1 }}. จำนวนเงิน: {{ deposit.amount }} บาท </span>
                <span> วันที่แจ้งชำระ: {{ deposit.date }} {{
                  deposit.time
                }}  น. โดยพนักงาน {{ deposit.employee.employee_name }}</span>
                <br>
                <span> หลักฐานการชำระ:
                  <a
                    :href="deposit.slip_path"
                    target="_blank"
                  > <span
                    style="color: red"
                  >กดเพื่อดูหลักฐานการชำระเงิน</span> <i
                    class="fa fa-cloud-download"
                    aria-hidden="true"
                  ></i></a></span><br>
                <span>โดยพนักงาน {{ deposit.employee.employee_name }}</span>
                <hr>
              </div>
            </div>
          </div>
          <div v-if="order.PT_type !== 'DP' && order.order_media">
            <hr class="mt-2 mb-2">
            <p>หลักฐานการชำระเงิน</p>
            <div v-for="(orderMedia,index) in order.order_media">
              <p v-if="orderMedia.media">
                <a
                  :href="orderMedia.media.path"
                  target="_blank"
                >
                  <span style="font-size: 9px;color: red">
                    {{ index + 1 }} .{{ orderMedia.media.name }}
                  </span>
                  <span
                    style="font-size: 9px"
                  > เมื่อ {{ orderMedia.media.created_at }}</span>
                  <i
                    class="fa fa-cloud-download"
                    aria-hidden="true"
                  ></i></a>
              </p>
            </div>

            <div v-if="order.payment_type === 'installment' && order.installments">
              <br>
              <span
                class="badge badge-warning"
                style="font-size: 14px"
              >ผ่อนชำระ {{ order.endTerm }} เดือน</span>
              <span
                v-if="order.bankType === 'KTC'"
                class="badge badge-success"
                style="font-size: 14px"
              >KTC : บัตรเคทีซี
              </span>
              <span
                v-if="order.bankType === 'BAY'"
                class="badge badge-success"
                style="font-size: 14px"
              >B AY : บัตรกรุงศรี วีซ่า , บัตรเซ็นทรัล , บัตรเทสโก้โลตัส </span>
              <span
                v-if="order.bankType !== 'BAY' && order.bankType !== 'KTC'"
                class="badge badge-success"
                style="font-size: 14px"
              >FCY : บัตรกรุงศรีเฟิร์สช้อยส์ , บัตรโฮมโปร , บัตรเมกาโฮม </span>

              <table
                class="table table-striped"
                style="font-size: 13px"
              >
                <thead>
                  <tr>
                    <th><strong>เดือน</strong></th>
                    <th><strong>จำนวนเงิน</strong></th>
                    <th><strong>สถานะ</strong></th>
                    <th><strong>ครบชำระเงิน</strong></th>
                    <th><strong>วันที่เช็คการจ่ายเงิน</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(installment,index) in order.installments">
                    <td>{{ index + 1 }}</td>
                    <td>{{ installment.amount }}</td>
                    <td>
                      <span
                        class="badge badge-success"
                      >{{ installment.payment_status }}</span>
                    </td>
                    <!--                  <td>{{installment.payment_start ? substr(installment.payment_start,0,10) :  '-'}}</td>-->
                    <td>
                      {{ installment.payment_start ? (installment.payment_start, 0, 10) : '-' }}
                    </td>
                    <td>{{ installment.payment_check_at ? installment.payment_check_at : '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="4"
    >
      <v-card>
        <v-card-text>
          <!--          <a download="{{asset($order->path_qr)}}"-->
          <!--             href="{{asset($order->path_qr)}}"-->
          <!--             title="QR-CODE">-->
          <!--            <v-img :src="{{asset($order->path_qr)}}" width="50%"-->
          <!--                 |:title="order.order_inv">-->
          <!--            <p>{{$order->order_inv}}</p>-->
          <!--          </a>-->
          <h3>{{ order.order_inv }}</h3>
          <qr-code
            v-if="order && order.order_inv"
            style="margin: 20px auto;"
            :text="order.order_inv"
          ></qr-code>
          <span v-if="order.PT_emp_id">
            <p style="color: rgba(104,189,255,0.6)">บิลรอการแจ้งโอน</p>
            <p>
              พนักงาน : {{ order.employee_pt ? order.employee_pt.employee_name : '-' }}
              <!--              ณ {{substr(order.PT_date,0,10)}}  {{order.PT_time}}-->
              ณ {{ order.PT_date }}  {{ order.PT_time }}
            </p>
          </span>
          <span v-if="order.PC_emp_id">
            <p style="color: #fad338">บิลตรวจสอบโดย</p>
            <p>
              พนักงาน : {{ order.employee_pc ? order.employee_pc.employee_name : '-' }}
              <!--              ณ {{substr(order.PC_date,0,10)}}  {{order.PC_time}}-->
              ณ {{ order.PC_date }}  {{ order.PC_time }}
            </p>
          </span>
          <span v-if="order.PD_emp_id">
            <p style="color: #f8182a">บิลผลิตโดย</p>
            <p>
              พนักงาน : {{ order.employee_pd ? order.employee_pd.employee_name : '-' }}
              <!--              ณ {{substr(order.PC_date,0,10)}}  {{order.PC_time}}-->
              ณ {{ order.PD_date }}  {{ order.PD_time }}
            </p>
          </span>
          <span v-if="order.PP_emp_id">
            <p style="color: #9634ef">บิลจัดส่งโดย</p>
            <p>
              พนักงาน : {{ order.employee_pp ? order.employee_pp.employee_name : '-' }}
              <!--              ณ {{substr(order.PC_date,0,10)}}  {{order.PC_time}}-->
              ณ {{ order.PP_date }}  {{ order.PP_time }}
            </p>
            <span v-if="order.PTP_emp_id">
              <p style="color: #18ff58">บิลเตรียมจัดส่ง/ส่งของ</p>
              <p>
                พนักงาน : {{ order.employee_ptp ? order.employee_ptp.employee_name : '-' }}
                <!--              ณ {{substr(order.PC_date,0,10)}}  {{order.PC_time}}-->
                ณ {{ order.PTP_date }}  {{ order.PTP_time }}
              </p>
            </span>
          </span>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-card>
        <v-card-text>
          <table class="table">
            <thead>
              <tr>
                <th class="text-center">
                  #
                </th>
                <th>รูป/ดาวน์โหลด</th>
                <th>รหัสลาย</th>
                <th>ชื่อลาย</th>
                <th>ยี่ห้อสินค้า</th>
                <th>รุ่นสินค้า</th>
                <th>จำนวน(ชิ้น)</th>
                <th>ราคา(บาท)</th>
                <th>ราคารวม(บาท)</th>
                <th>หมายเหตุ*</th>
                <th>เครื่องมือ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order_detail,index) in order_details">
                <td class="text-center">
                  {{ index + 1 }}
                </td>
                <td>
                  <div
                    class="fileupload fileupload-new"
                    data-provides="fileupload"
                  >
                    <div
                      class="fileupload-new thumbnail"
                    >
                      <div
                        v-if="order_detail.pattern.pattern_img && order_detail.pattern && order_detail.pattern.pattern_code != 'สั่งเพิ่มเติม'"
                      >
                        <a
                          :href="order_detail.pattern.pattern.pattern_img"
                          title=""
                          target="_blank"
                        >
                          <!--                          <img-->
                          <!--                            onerror="imgError(this);"-->
                          <v-img
                            :src="order_detail.pattern.pattern_img"
                            style="width:50px;height: 50px;"
                          >>
                          </v-img>
                        </a>
                      </div>
                      <div
                        v-if="!order_detail.pattern.pattern_img"
                      >
                        <div
                          v-if="order_detail.pattern.location_files"
                        >
                          <a
                            title="รูปอัพโหลดไฟล์เข้าระบบ"
                            target="_blank"
                            :href="order_detail.pattern.location_files"
                          >ไฟล์</a>
                          <br>
                        </div>
                        <div
                          v-if="!order_detail.pattern.pattern_img && order_detail.pattern.example_picture"
                        >
                          <div
                            v-if="order_detail.pattern.example_picture"
                          >
                            <a
                              :href="order_detail.pattern.example_picture"
                              title="ตัวอย่างรูป"
                              target="_blank"
                              style="color: rgb(255, 77, 77)"
                            >
                              <span
                                style="font-size: 11px"
                              >ตัวอย่าง </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {{ order_detail.pattern.pattern_code }}
                </td>
                <td>
                  {{ order_detail.pattern.pattern_name }}
                </td>
                <td>
                  {{
                    order_detail.product && order_detail.product.brand ? order_detail.product.brand.phone_brand_name : '-'
                  }}
                </td>
                <td>
                  {{ order_detail.product ? order_detail.product.product_name : '-' }}
                </td>
                <td>
                  <!--                <center>-->
                  <!--                  @if(($order->PT_type === 'T' || $order->PT_type === 'CD' || $order->PT_type === 'C') && $order->additional_payment === 'N' && ($order->order_status === 'PT' || $order->order_status === 'PC'))-->
                  <!--                  <input-->
                  <!--                    name="order_detail_id[]"-->
                  <!--                    v-model="order_detail.order_detail_id"-->
                  <!--                    type="hidden">-->
                  <!--                  <number-input-->
                  <!--                    placeholder="จำนวน"-->
                  <!--                    inline-->
                  <!--                    controls-->
                  <!--                    name="product_amount[]"-->
                  <!--                    v-model="order_detail.product_amount"></number-input>-->
                  <!--                  @else-->
                  <!--                  @-->
                  <!--                  @endif-->
                  <span
                    v-if="((order.PT_type === 'T' || order.PT_type === 'CD' || order.PT_type === 'C') && order.additional_payment === 'N' && (order.order_status === 'PT' || order.order_status === 'PC'))"
                  >
                    <v-text-field
                      v-model="order_detail.product_amount"
                      type="number"
                      placeholder="จำนวน"
                      name="product_amount[]"
                    ></v-text-field>
                  </span>
                  <span
                    v-if="!((order.PT_type === 'T' || order.PT_type === 'CD' || order.PT_type === 'C') && order.additional_payment === 'N' && (order.order_status === 'PT' || order.order_status === 'PC'))"
                    class="text-center"
                  >{{ order_detail.product_amount }}</span>

                <!--                </center>-->
                </td>
                <td>
                  <span
                    v-if="(order.PT_type === 'T' || order.PT_type === 'CD' || order.PT_type === 'C') && order.additional_payment === 'N' && (order.order_status === 'PT' || order.order_status === 'PC')"
                  >
                    <v-text-field
                      v-model="order_detail.product_price"
                      type="number"
                      placeholder="ราคา"
                      name="product_price[]"
                    ></v-text-field>
                  </span>
                  <span v-if="!((order.PT_type === 'T' || order.PT_type === 'CD' || order.PT_type === 'C') && order.additional_payment === 'N' && (order.order_status === 'PT' || order.order_status === 'PC'))">
                    {{ order_detail.product_price }}
                  </span>
                  <!--                @if(($order->PT_type === 'T' || $order->PT_type === 'CD' || $order->PT_type === 'C') && $order->additional_payment === 'N' && ($order->order_status === 'PT' || $order->order_status === 'PC'))-->
                  <!--                <number-input-->
                  <!--                  placeholder="ราคา" inline-->
                  <!--                  name="product_price[]"-->
                  <!--                  v-model="order_detail.product_price"></number-input>-->
                  <!--                <input type="hidden"-->
                  <!--                       name="product_id[]"-->
                  <!--                       v-model="order_detail.product_id">-->
                  <!--                <input type="hidden"-->
                  <!--                       name="product_costs[]"-->
                  <!--                       v-model="order_detail.product_costs">-->
                  <!--                <input type="hidden"-->
                  <!--                       name="product_type_id[]"-->
                  <!--                       v-model="order_detail.product_type_id">-->
                  <!--                @else-->
                  <!--                @{{ order_detail.product_price}}-->
                  <!--                @endif-->
                  <span
                    v-if="order_detail.node"
                    style="color:red;font-size: 11px"
                  >
                    <br>{{ order_detail.node || '' }}
                  </span>
                </td>
                <td>
                  {{ order_detail.product_price * order_detail.product_amount }}
                </td>
                <td>
                  {{ order_detail.pattern && order_detail.pattern.pattern_detail ? order_detail.pattern.pattern_detail : '-' }}
                </td>
                <td>
                  <v-btn
                    v-if="(order.PT_type === 'T' || order.PT_type === 'DP' || order.PT_type === 'C') && order.additional_payment === 'N' && (order.order_status === 'PT' || order.order_status === 'PC')"
                    class="warning-badge"
                    @click="openChangeModel(index)"
                  >
                    <!--                  @click="listProduct(order_detail.product_type_id,index)"-->
                    เปลี่ยนรุ่น
                  </v-btn>
                <!--                @if(($order->PT_type === 'T' || $order->PT_type === 'DP' || $order->PT_type === 'C') && $order->additional_payment === 'N' && ($order->order_status === 'PT' ||  $order->order_status === 'PC'))-->
                <!--                                <button class="btn btn-warning"-->
                <!--                                        data-toggle="modal"-->
                <!--                                        data-target="#select_products"-->
                <!--                                        type="button"-->
                <!--                                        v-on:click="listProduct(order_detail.product_type_id,index)"-->
                <!--                                        title="เปลี่ยนรุ่น">-->
                <!--                                  เปลี่ยนรุ่น <i-->
                <!--                                  style="font-size: 13px; color: white"-->
                <!--                                  class="fa fa-pencil-square-o"-->
                <!--                                  aria-hidden="true"></i>-->
                <!--                                </button>-->
                <!--                -->
                <!--                                @endif-->
                </td>
              </tr>
            </tbody>

            <!--            modal เปลี่ยนรุ่น-->
            <!--            <div class="modal fade" id="select_products"-->
            <!--                 role="dialog">-->
            <!--              <div class="modal-dialog modal-lg"-->
            <!--                   role="document">-->
            <!--                <div class="modal-content">-->
            <!--                  <div class="modal-header">-->
            <!--                    <h4 class="modal-title">-->
            <!--                      เปลี่ยนรุ่น</h4>-->
            <!--                    <button type="button" class="close"-->
            <!--                            data-dismiss="modal"-->
            <!--                            aria-label="Close">-->
            <!--                                                                                        <span-->
            <!--                                                                                          aria-hidden="true">&times;</span>-->
            <!--                    </button>-->
            <!--                  </div>-->
            <!--                  <div class="modal-body">-->

            <!--                    <div class="container">-->
            <!--                      <div class="row">-->
            <!--                        <div class="col-md-12">-->
            <!--                          <div-->
            <!--                            class="col-md-12 id_select">-->
            <!--                            <v-select-->
            <!--                              label="name"-->
            <!--                              :options="product"-->
            <!--                              v-if="product"-->
            <!--                              placeholder="กรุณาพิมพ์ค้นหารุ่นที่สั่งผลิต"-->
            <!--                              v-model="select_product"-->

            <!--                            >-->
            <!--                            </v-select>-->
            <!--                          </div>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                    <br>-->
            <!--                  </div>-->
            <!--                  <div class="modal-footer">-->
            <!--                    <button type="button"-->
            <!--                            class="btn btn-secondary"-->
            <!--                            data-dismiss="modal">ปิด-->
            <!--                    </button>-->
            <!--                    <button type="button"-->
            <!--                            class="click-close btn btn-primary "-->
            <!--                            v-if="select_product && select_product.product_type_id"-->
            <!--                            v-on:click="selectProduct()"-->
            <!--                            data-dismiss="modal">-->
            <!--                      เปลี่ยนรุ่น-->
            <!--                    </button>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            modal เปลี่ยนรุ่น-->
          </table>
          <table width="100%">
            <tbody>
              <tr>
                <td style="text-align: right;font-size: 24px">
                  รวมยอด : <span
                    style="color:red; font-size: 27px"
                  >
                    {{ parseFloat(sub_total()).toFixed(2) }}
                  </span>
                </td>
              </tr>
              <tr>
                <td style="text-align: right;font-size: 16px">
                  <span
                    style="margin-right: 30px;font-size: 24px;color: black"
                  >
                    วิธีการส่งสินค้า :
                  </span>
                  <span
                    v-if="order.order_status === 'PT' || order.order === 'PC'"
                    style="width: 200px;"
                    class="float-right"
                  >
                    <v-select
                      v-model="order.transport_type_id"
                      label="Transport Type"
                      :items="transport_list"
                      item-text="transport_type_name"
                      item-value="transport_type_id"
                      @change="settransport"
                    ></v-select>
                    <p style="color: red">
                      แก้ไขวิธีจัดส่งได้เฉพาะสถานะ
                      รอโอน / รอตรอบสอบ เท่านั้น.
                    </p>
                  </span>
                  <span
                    v-if="order.order_status !== 'PT' && order.order !== 'PC'"
                  >
                    {{ order.transport_type ? order.transport_type.transport_type_name :'-' }}
                  </span>
                </td>
              </tr>

              <tr>
                <td style="text-align: right;font-size: 24px;color: black">
                  <span style=";color: black">ค่าจัดส่ง:</span>
                  <span
                    style=";color: red"
                  >   {{ parseFloat(order.transport_expense_price).toFixed(2) }}</span>
                </td>
              </tr>
              <tr>
                <td style="text-align: right;font-size: 24px">
                </td>
              </tr>

              <tr>
                <td style="text-align: right;font-size: 30px;color: black">
                  <span style="; font-size: 24px;font-weight: bolder">
                    รวม :  <span style=";color: red">  {{ (parseFloat(sub_total())+ parseFloat(order.transport_expense_price)).toFixed(2) }} </span>
                  </span>
                </td>
              </tr>
              <tr v-if="vat() && vat(0) > 0">
                <td style="text-align: right;font-size: 30px;color: black">
                  <span style="; font-size: 24px;font-weight: bolder">
                    Vat 7% :  <span style=";color: red">  {{ (parseFloat(vat())).toFixed(2) }} </span>
                  </span>
                </td>
              </tr>

              <tr>
                <td style="text-align: right;font-size: 30px;color: black">
                  <span style="; font-size: 24px;font-weight: bolder">
                    ค่าบริการเร่งด่วน :  <span style=";color: red"> {{ parseFloat(total_importune()).toFixed(2) }} </span>
                  </span>
                </td>
              </tr>
              <!--            <tr>-->
              <!--              <td style="text-align: right;font-size: 30px;color: black">-->
              <!--                <span style="; font-size: 24px;font-weight: bolder">-->
              <!--                  รวม Vat 7%:  <span style=";color: red"> {{ ((parseFloat(sub_total()) + parseFloat(total_importune()) + parseFloat(order.transport_expense_price))  * 0.07).toFixed(2)}} </span>-->
              <!--                </span>-->
              <!--              </td>-->
              <!--            </tr>-->
              <tr>
                <td style="text-align: right;font-size: 30px;color: black">
                  <span style="; font-size: 24px;font-weight: bolder">
                    ค่าบริการอื่นๆ :  <span style=";color: red"> {{ parseFloat(totalConfigs()).toFixed(2) }} </span>
                  </span>
                </td>
              </tr>
              <tr>
                <td style="text-align: right;font-size: 24px;color: black">
                  <div style="display: inline">
                    <v-row>
                      <v-col
                        sm="10"
                        md="11"
                        cols="9"
                      >
                        ส่วนลดพิเศษ :
                      </v-col>
                      <v-col
                        sm="2"
                        md="1"
                        cols="3"
                      >
                        <v-text-field
                          v-model="order.order_price_discount"
                          style="width: 100px;margin-top: -15px;"
                          type="number"
                          placeholder="ส่วนลดพิเศษ"
                          min="0"
                          :max="parseFloat((parseInt(sub_total()+vat()+total_importune()+totalConfigs())+parseInt(order.transport_expense_price)-parseInt(order.order_price_discount))).toFixed(2)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </td>
              </tr>

              <tr>
                <td style="text-align: right;font-size: 30px;color: black">
                  ยอดรวมทั้งสิ้น :
                  <!--                {{sub_total()}}-->
                  <!--                {{order.transport_expense_price}}-->
                  <!--                {{total_importune}}-->
                  <!--                {{totalConfigs()}}-->
                  <!--                {{order.order_price_discount}}-->
                  <span
                    style="color:red; font-size: 30px;font-weight: bolder"
                  >
                    {{ total() }}</span>
                  <!--                  {{ parseFloat(order.order_expense) }}</span>-->
                  <!--                <span-->
                  <!--                  style="color:red; font-size: 30px;font-weight: bolder">{{ parseFloat((parseInt(sub_total())+parseInt(order.transport_expense_price)-parseInt(order.order_price_discount))).toFixed(2)}}</span>-->
                  <!--                                <v-text-field-->
                  <!--                                       type="hidden"-->
                  <!--                                       v-model="parseFloat((parseInt(sub_total+total_importune+totalConfigs)+parseInt(data.transport_expense_price)-parseInt(order_price_discount))).toFixed(2)"-->
                  <!--                                       name="order_expense_new">-->
                </td>
              </tr>
              <tr>
              </tr>
              <tr>
                <td style="text-align: right">
                  <br>
                <!--                <input v-model="order_expense"-->
                <!--                       name="order_expense"-->
                <!--                       type="hidden">-->
                <!--                <input-->
                <!--                  v-model="order_price_discount"-->
                <!--                  name="order_price_discount"-->
                <!--                  type="hidden">-->
                <!--                <input class="form-control"-->
                <!--                       type="hidden"-->
                <!--                       name="sender_name"-->
                <!--                       v-model="data.sender_name">-->
                <!--                <input class="form-control"-->
                <!--                       type="hidden"-->
                <!--                       name="sender_address"-->
                <!--                       v-model="data.sender_address">-->
                <!--                <input class="form-control"-->
                <!--                       type="hidden"-->
                <!--                       name="recipient_name"-->
                <!--                       v-model="data.recipient_name">-->
                <!--                <input class="form-control"-->
                <!--                       type="hidden"-->
                <!--                       name="recipient_address"-->
                <!--                       v-model="data.recipient_address">-->
                <!--                <input class="form-control"-->
                <!--                       type="hidden"-->
                <!--                       name="order_detail_oth"-->
                <!--                       v-model="data.order_detail_oth">-->
                <!--                <input class="form-control"-->
                <!--                       type="hidden"-->
                <!--                       name="order_detail_oth"-->
                <!--                       v-model="data.order_expense_oth">-->
                <!--                <input class="form-control"-->
                <!--                       type="hidden"-->
                <!--                       name="transport_expense_price"-->
                <!--                       v-model="data.transport_expense_price">-->
                <!--                <input class="form-control"-->
                <!--                       type="hidden"-->
                <!--                       name="transport_type_id"-->
                <!--                       v-model="data.transport_type_id">-->
                </td>
              </tr>
              <!--            <tr>-->
              <!--              <th colspan="9">-->
              <!--                <label class="badge badge-warning">-->
              <!--                  <h3>-->
              <!--                    <i class="fa fa-exclamation-circle"-->
              <!--                       aria-hidden="true"></i>แจ้งสถานะบิลเพิ่มเติม-->
              <!--                  </h3>-->
              <!--                </label>-->
              <!--                <v-textarea-->
              <!--                  v-model="order.status_oth"-->
              <!--                  placeholder="แจ้งสถานะบิลเพิ่มเติม กรณีแจ้งลุกค้า (ใช้เวลาสกรีนนานขึ้น 1-2วัน /รอสินค้าเข้า/ อื่นๆ)"-->
              <!--                ></v-textarea>-->
              <!--                <br>-->
              <!--              </th>-->
              <!--            </tr>-->
              <tr>
                <th colspan="9">
                  <button
                    type="submit"
                    style="width: 100%;color: white"
                    onclick="return confirm('คุณต้องการแก้ไขข้อมูลใช่หรือไม่?')"
                    class="btn btn-square btn-success"
                  >
                    <i
                      aria-hidden="true"
                      class="fa fa-save"
                    ></i>
                    บันทึกข้อมูล
                  </button>
                </th>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-card>
        <v-card-text>
          <v-textarea
            v-model="order.status_oth"
            label="แจ้งสถานะบิลเพิ่มเติม"
            placeholder="แจ้งสถานะบิลเพิ่มเติม กรณีแจ้งลุกค้า (ใช้เวลาสกรีนนานขึ้น 1-2วัน /รอสินค้าเข้า/ อื่นๆ)"
          ></v-textarea>
          <v-btn

            v-if="($store.state.user.member_id && ( order.order_status == 'PT' || order.order_status == 'PC' || order.order_status == 'DF')) || !$store.state.user.member_id"
            color="primary"
            @click="save()"
          >
            บันทึกข้อมูล
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              md="6"
            >
              <v-text-field
                v-model="order.sender_name"
                label="(ที่อยู่ผู้ส่ง) ชื่อ:"
              ></v-text-field>

              <v-textarea
                v-model="order.sender_address"
                label="ที่อยู่:"
              ></v-textarea>
            </v-col>
            <v-col
              cols="6"
              md="6"
            >
              <v-text-field
                v-model="order.recipient_name"
                label="(ที่อยู่ผู้รับ) ชื่อ:"
              ></v-text-field>
              <v-textarea
                v-model="order.recipient_address"
                label="ที่อยู่:"
              ></v-textarea>
            </v-col>
          </v-row>

          <!--          <p v-if="order.address_member_file">-->
          <!--            <a-->
          <!--            :href="order.address_member_file" target="_blank">กดดูไฟล์ที่อยู่ผู้รับและผู้ส่ง</a>-->
          <!--          </p>-->
          <v-btn
            v-if="order.address_member_file"
            color="secondary"
            @click="openLink(order.address_member_file)"
          >
            กดเพื่อดูไฟล์
          </v-btn>

          <v-textarea
            v-model="order.order_detail_oth"
            label="รายละเอียดเพิ่มเติม"
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <!--      <div class="card" v-if="data.address_member_file">-->
      <!--        <div class="card-body"><label class="badge badge-success"><input-->
      <!--          type="checkbox" v-model="show_address_file"> แสดงข้อมูล-->
      <!--          ไฟล์ที่อยู่ ผู้รับและผู้ส่ง </label></div>-->
      <!--        <div v-if="show_address_file" class="card-body">-->
      <!--          @if($order->address_member_file)-->
      <!--          <div>-->
      <!--            <center>-->
      <!--              <h6>-->
      <!--                <a href="/{{$order->address_member_file}}"-->
      <!--                   target="_blank"> <span-->
      <!--                  style="font-size: 16px"-->
      <!--                  class="badge badge-warning"-->
      <!--                  title="กดเพื่อดู"> &nbsp;&nbsp; กดเพื่อดู &nbsp;&nbsp;</span></a>-->
      <!--              </h6>-->
      <!--              <p style="color: red"><i class="fa fa-file"-->
      <!--                                       aria-hidden="true"></i>  * มีไฟล์-->
      <!--                ไฟล์ที่อยู่ ผู้รับและผู้ส่ง </p>-->
      <!--            </center>-->

      <!--          </div>-->
      <!--          @endif-->
      <!--        </div>-->
      <!--      </div>-->
    </v-col>

    <!--            modal เปลี่ยนรุ่น-->
    <!--            <div class="modal fade" id="select_products"-->
    <!--                 role="dialog">-->
    <!--              <div class="modal-dialog modal-lg"-->
    <!--                   role="document">-->
    <!--                <div class="modal-content">-->
    <!--                  <div class="modal-header">-->
    <!--                    <h4 class="modal-title">-->
    <!--                      เปลี่ยนรุ่น</h4>-->
    <!--                    <button type="button" class="close"-->
    <!--                            data-dismiss="modal"-->
    <!--                            aria-label="Close">-->
    <!--                                                                                        <span-->
    <!--                                                                                          aria-hidden="true">&times;</span>-->
    <!--                    </button>-->
    <!--                  </div>-->
    <!--                      <div class="modal-body">-->

    <!--                        <div class="container">-->
    <!--                          <div class="row">-->
    <!--                            <div class="col-md-12">-->
    <!--                              <div-->
    <!--                                class="col-md-12 id_select">-->
    <!--                                -->
    <!--                              </div>-->
    <!--                            </div>-->
    <!--                          </div>-->
    <!--                        </div>-->
    <!--                        <br>-->
    <!--                      </div>-->
    <!--                      <div class="modal-footer">-->
    <!--                        <button type="button"-->
    <!--                                class="btn btn-secondary"-->
    <!--                                data-dismiss="modal">ปิด-->
    <!--                        </button>-->
    <!--                        <button type="button"-->
    <!--                                class="click-close btn btn-primary "-->
    <!--                                v-if="select_product && select_product.product_type_id"-->
    <!--                                v-on:click="selectProduct()"-->
    <!--                                data-dismiss="modal">-->
    <!--                          เปลี่ยนรุ่น-->
    <!--                        </button>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--            </div>-->
    <!--            modal เปลี่ยนรุ่น-->
    <v-dialog
      v-model="dialogChange"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="text-h5 mb-5">
          เปลี่ยนรุ่น
        </v-card-title>
        <v-card-text>
          <v-select
            v-if="products"
            v-model="select_product"
            label="สินค้า"
            :items="products"
            :item-text="item => item.product_name +' - จำนวนคงเหลือ '+ item.remaining_online + ' (ชิ้น)' "
            item-value="product_id"
            placeholder="กรุณาพิมพ์ค้นหารุ่นที่สั่งผลิต"
            outlined
            dense
            hide-details
            class="mt-2"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="st"
                    placeholder="Search"
                    @input="product_search"
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeChangeModel"
          >
            ยกเลิก
          </v-btn>
          <!--          @click="selectProduct()"-->
          <v-btn
            color="primary"
            :disabled="!select_product"
            @click="changeModel()"
          >
            เปลี่ยนรุ่น
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiCartMinus, mdiMagnify, mdiTruck, mdiPrinterSearch,
} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Swal from 'sweetalert2'
import instance from '@/axios.service'
import instance_order from '@/services/order'
import instance_config from '@/services/config'
import instance_transport_type from '@/services/transportType'
import transportTypeAPI from '@/services/transportType'
import user from '@/services/user'
import instance_phone_models from '@/services/phoneModel'
import patternAPI from '@/services/pattern'

export default {
  components: { DatePicker },
  props: {
    order_id: { type: [String, Number] },
    url_tracking: { type: Object },
    order: { type: Object },
    order_details: { type: Array },
  },
  setup() {
    return {
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
      },
      print,
    }
  },
  data() {
    return {
      // TextCopy: 'ขั้นตอนหลังการโอนเงินเสร็จแล้ว..\n' +
      //   '1. ลิงค์ด้านบนที่ร้านส่งไป ลูกค้าเข้าไปเช็ครายละเอียดความถูกต้องของชื่อรุ่นโทรศัพท์ และลายที่ได้สั่งนะค้าบ\n' +
      //   '2. รอรับของจากขนส่งได้เลยครับใน 2-4 วันทำการ\n' +
      //   '3. หากสินค้ายังไม่ได้รับเกิน 1 อาทิตย์ ให้เช็คที่ตู้ไปรษณีย์ของตัวเองจะมีใบรับพัสดุทิ้งไว้ ให้ไปรับก่อน 14 วันทำการไม่งั้นของจะตีกลับมาหาทางร้านนะครับ\n' +
      //   '\n' +
      //   'ลิงค์จะเปลี่ยนเป็นจัดส่งตอนไหน?..\n' +
      //   '1. เมื่อร้านได้รับยอดโอนเงินแล้ว 1-2 วันลิงค์จะเปลี่ยนเป็นจัดส่งของ\n' +
      //   '2. เมื่อเปลี่ยนเป็นจัดส่งแล้วจะมีเลขพัสดุขึ้น '+"{{$order->PP_tax_no ? $order->PP_tax_no : 'ตัวอย่าง.. EV XXXXXXXX TH' }}"+'\n' +
      //   '3. ลูกค้าเอาเลขที่ได้รับไปกรอก-เช็คในเว็บไซต์\'\n' +
      //   '\''+"{{$order->trackingUrl($order->transport_type_id)->url ? $order->trackingUrl($order->transport_type_id)->url : ''}}"+'\n' +
      //   '4. ลูกค้าจะทราบสถานะของว่าสินค้าอยู่ที่ไหน ขั้นตอนอะไร ของส่งหรือยังไม่ส่งจะมีแจ้งไว้หมดครับ\n' +
      //   '5. หลังจากนั้น..ไปรษณีย์ไทยจะนำจ่ายพัสดุสินค้าให้ลูกค้าถึงหน้าบ้านครับ\n' +
      //   '\n' +
      //   'เมื่อได้รับของแล้ว..\n' +
      //   '1. ลูกค้าเช็คว่าใส่ได้ใส่ไม่ได้ทันที สินค้าจะเคลมได้ภายใน 7-14 วันตามปัญหาที่พบกับตัวเคส\n' +
      //   '2. เพื่อกำลังใจในการผลิตเคสของพี่ต่อไป รบกวนลูกค้ารีวิวกลับมาเป็นน้ำใจดีๆ ให้กับพี่ด้วยนะค้าบผม ขอบคุณมากๆ เลยค้าบ\'\n',
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'numberBill',
        },
        { text: 'รูป/ดาวน์โหลด', value: 'timeBill' },
        { text: 'รหัสลาย', value: 'name' },
        { text: 'ชื่อลาย', value: 'total' },
        { text: 'ยี่ห้อสินค้า', value: 'total' },
        { text: 'รุ่นสินค้า', value: 'total' },
        { text: 'จำนวน(ชิ้น)', value: 'total' },
        { text: 'ราคา(บาท)', value: 'total' },
        { text: 'ราคารวม(บาท)', value: 'total' },
        { text: 'เครื่องมือ', value: 'status' },
      ],
      importune: 'N',
      transport_type: null,
      transport_list: [],
      configs: [],
      dialogChange: false,
      products: [],
      select_product: null,
      st: '',
      index_order_detail: 0,
    }
  },

  watch: {
    order: {
      handler() {
        this.importune = this.order.importune
      },
    },
  },
  mounted() {
    this.getConfig()
    this.getTransportType()
  },
  methods: {
    settransport() {
      if (this.order.transport_type_id) {
        transportTypeAPI
          .apiTransportExpensePriceRate(this.amount(), this.order.transport_type_id)
          .then(res => {
            if (res.status === 200) {
              this.order.transport_expense_price = res.data
              this.validateForm()
            }

            // removrToken
            user.removrToken(res)
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    product_search() {
      this.products = []
      const { member_type_id } = this.order.member
      const data = {
        member_type_id,
        product_type_id: this.select_product ? this.select_product : null,
        search: this.st ? this.st : '',
      }
      patternAPI.getPatternProduct(data).then(res => {
        Object.entries(res.data.data).forEach(([key, val]) => {
          let product_amount = parseInt(val.product_amount)
          if (!product_amount || product_amount === '0' || product_amount === 0 || product_amount < 0) {
            product_amount = 0
          }

          console.log(val, 111)
          const product = {
            image_path: val.image_path,
            phone_brand_name: val.brand.phone_brand_name,
            phone_model_name: val.phone_model.phone_model_name,
            product_code: val.product_code,
            pattern_code: this.select_product ? this.select_product.pattern_code : '',
            pattern_status: this.select_product ? this.select_product.pattern_status : '',
            product_costs: val.product_costs,
            product_price: val.price.product_price,
            product_price_old: val.product_price_old,
            remaining_online: val.remaining_online,
            product_id: val.product_id,
            product_type_id: val.product_type_id,
            product_name: val.product_name,

            // 'name': val.product_name + ' รุ่น: ' + val.phone_model_name + '  จำนวนคงเหลือ: ' + val.product_amount + ' (ชิ้น)',
            name: `${val.product_name}  จำนวนคงเหลือ: ${product_amount} (ชิ้น)`,
            product_amount,
            weight: val.weight,
          }
          this.products.push(product)
        })
      }).catch(err => {
        console.log(err)
      })
    },
    getConfig() {
      const data = {
        order_id: this.order_id,
      }
      this.$store.state.isLoading = true
      instance_config.getConfigOrder(data).then(res => {
        this.configs = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    getTransportType() {
      const data = {
        search: '',
        size: 9999,
        page: 1,
      }
      this.$store.state.isLoading = true
      instance_transport_type.get(data).then(res => {
        this.transport_list = res.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
        Swal.fire({
          text: err.message,
          timer: 1500,
          icon: 'error',
          showConfirmButton: false,
          button: false,
        })
      })
    },
    openChangeModel(index) {
      this.product_search()
      this.dialogChange = true
      this.select_product = null

      this.index_order_detail = index
    },
    closeChangeModel(index) {
      this.dialogChange = false
      this.select_product = null
      this.index_order_detail = null
    },
    changeModel() {
      this.dialogChange = false
      let product = null

      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].product_id == this.select_product) {
          product = this.products[i]
        }
      }

      const index = this.index_order_detail

      if (this.select_product) {
        // <td>
        //   {{ order_detail.pattern.pattern_code }}
        // </td>
        // <td>
        //   {{ order_detail.pattern.pattern_name }}
        // </td>
        // <td>
        //   {{
        //     order_detail.product && order_detail.product.brand ? order_detail.product.brand.phone_brand_name : '-'
        //   }}
        // </td>
        // <td>
        //   {{ order_detail.product ? order_detail.product.product_name : '-' }}
        // </td>
        this.order_details[index].product_id = product.product_id
        this.order_details[index].product_type_id = product.product_type_id
        this.order_details[index].product_costs = product.product_costs
        this.order_details[index].phone_model_name = product.phone_model_name
        this.order_details[index].product_name = product.product_name
        this.order_details[index].pattern.pattern_name = product.product_name
        this.order_details[index].product.product_name = product.product_name
        this.order_details[index].phone_brand_name = product.phone_brand_name
        this.order_details[index].product.brand.phone_brand_name = product.phone_brand_name
        this.order_details[index].product_price = product.product_price
        this.select_product = null

        // this.CheckAmountByProductType();
        Swal.fire({
          text: `รุ่นที่ต้องเปลี่ยนคือ ${this.order_details[index].product_name}`,
          timer: 3000,
          icon: 'success',
          showConfirmButton: false,
          button: false,
        })
      } else {
        Swal.fire({
          text: 'ไม่พบรุ่นที่ต้องการเปลี่ยน กรุณาเลือกรุ่นที่ต้องการเปลี่ยน',
          timer: 3000,
          icon: 'error',
          showConfirmButton: false,
          button: false,
        })
      }
    },
    transportExpense() {
      if (this.transport_type && this.transport_type.transport_type_id) {
        this.order.transport_type_name = this.transport_type.transport_type_name
        this.order.transport_type_id = this.transport_type.transport_type_id
        const check = true
        if (check) {
        //   axios.get('{{url('api/apiTransportExpensePriceRate')}}' + '?transport_type_id=' + this.transport_type.transport_type_id + '&amount=' + this.weight)
        // .then(response => {
        //     if (response.data && response.data.price) {
        //
        //       this.data.transport_expense_price = response.data.price;
        //       this.transport_expenseCheck = false;
        //     } else {
        //       axios.get('{{url('api/user/transportExpense')}}' + '/' + this.transport_type.transport_type_id + '?amount=' + this.amount)
        //     .then(response => {
        //         if (response.data.transport_expense_price) {
        //           this.data.transport_expense_price = response.data.transport_expense_price;
        //         } else {
        //           this.data.transport_expense_price = 0;
        //         }
        //         this.transport_expenseCheck = false;
        //       })
        //         .catch(function (error) {
        //           Swal.fire({
        //             text: error.message,
        //             timer: 1500,
        //             icon: 'error',
        //             showConfirmButton: false,
        //             button: false
        //           })
        //           console.log(error.message);
        //           return false
        //         });
        //     }
        //
        //   })
        //     .catch(function (error) {
        //       swal({
        //         text: error.message,
        //         timer: 3000,
        //         icon: 'error',
        //         showConfirmButton: false,
        //         button: false
        //       })
        //       console.log(error.message);
        //       return false
        //     });
          //! transport_expense_rates
        }
      }
    },
    copyTrackingUrl() {
      /* Get the text field */
      const copyText = document.getElementById('url_tacking')

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy')

      /* Alert the copied text */
      alert(`คัดลอกเลขพัสดุก่อน: ${copyText.value}`)
    },
    totalConfigs() {
      const total = []
      Object.entries(this.configs).forEach(([key, val]) => {
        if (val.check && val.id != 2) {
          if (this.amount() < 6) {
            // val.value = this.amount()*40;
            total.push(parseInt(this.amount()) * parseInt(val.value))
          } else if (this.amount() > 5 && this.amount() < 11) {
            val.value = this.amount() * 35

            // total.push(this.amount()*35);
          } else {
            total.push(this.amount() * 30)

            // val.value = this.amount()*30;
          }
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    amount() {
      const total = []
      Object.entries(this.order_details).forEach(([key, val]) => {
        if (val.product_amount) {
          total.push(parseInt(val.product_amount))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    total_importune() {
      const total = []
      total.push(0)
      if (this.importune == 'Y') {
        if (this.amount() < 13) {
          total.push(this.amount() * 20)
        } else if (this.amount() > 12 && this.amount() < 50) {
          total.push(this.amount() * 15)
        } else {
          total.push(this.amount() * 10)
        }
      } else {
        total.push(0)
      }

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    sub_total() {
      const total = []
      Object.entries(this.order_details).forEach(([key, val]) => {
        if (val.product_amount && val.product_price) {
          total.push(parseInt(val.product_amount) * parseInt(val.product_price))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    vat() {
      let total = 0
      Object.entries(this.configs).forEach(([key, val]) => {
        if (val.check && val.id == 5) {
          total = (parseFloat(this.sub_total()) + parseFloat(this.order.transport_expense_price))
        }
      })

      return total * 0.07
    },
    total() {
      const discount = this.order.order_price_discount
      const transport = parseInt(this.order.transport_expense_price)
      const total = parseFloat((this.sub_total() + this.vat() + this.total_importune() + this.totalConfigs() + transport - discount))

      return total.toFixed(2)
    },
    openLink(url) {
      window.open(url, '_blank', 'noreferrer')
    },
    save() {
      Swal.fire({
        title: 'คุณต้องการแก้ไขข้อมูลใช่หรือไม่',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            const data = {}
            this.$store.state.isLoading = true
            this.order.order_expense_new = this.total()
            this.order.status_oth = this.order.status_oth ? this.order.status_oth : ''
            instance_order.updateBookingOrder(this.order, this.order_details).then(res => {
              this.$store.state.isLoading = false
              if (res.data.success) {
                Swal.fire({
                  icon: 'success',
                  title: res.data.message,
                  showConfirmButton: false,
                  timer: 3000,
                })
              }
            }).catch(err => {
              this.$store.state.isLoading = false
              Swal.fire({
                text: err.message,
                timer: 3000,
                icon: 'error',
                showConfirmButton: false,
                button: false,
              })
            })
          }
        })
    },
  },
}
</script>

<style scoped>
</style>
