<template>
  <v-row>
    <v-col
      cols="12"
      md="12">
      <v-card>
        <v-card-text>
          <center>
            <h1 style="font-size: 20px" class="">
              รูปภาพรีวิวสินค้าจากร้านทั้งหมด
            </h1>
          </center>
          <div v-if="order && order.order_media &&  order.order_media.length > 0">
            <div class="card-body" style="margin-top: 100px">
              <div v-for="media in order.order_media">
                <center>
                  <a class="" :href="media.path" target="_blank">
                    <v-img :src="media.path" width="50%"></v-img>
                  </a>
                </center>
              </div>
            </div>

          </div>
          <div v-if="order && !order.media">
            <div class="alert alert-danger text-lg" role="alert"
                 style="margin-top: 300px">
              <center>
                <br>
                <br>
                <br>
                <h1 style="color: black;font-size: 40px"
                    class="margin-top margin-bottom-lg">
                  <i class="fa fa-meh-o" aria-hidden="true"></i>
                  ไม่พบรูปภาพรีวิวสินค้าจากร้าน</h1>
                <br>
                <br>
                <br>
              </center>
            </div>
          </div>

        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mdiCartMinus, mdiMagnify, mdiTruck, mdiPrinterSearch} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {DatePicker},
  props: {
    order_id: {type: [String, Number]},
    order: {type: Object},
  },
  setup() {

    return {
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
      },
      print,
    }
  },
  data() {
    return {

      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'numberBill',
        },
        {text: 'รูป/ดาวน์โหลด', value: 'timeBill'},
        {text: 'รหัสลาย', value: 'name'},
        {text: 'ชื่อลาย', value: 'total'},
        {text: 'ยี่ห้อสินค้า', value: 'total'},
        {text: 'รุ่นสินค้า', value: 'total'},
        {text: 'จำนวน(ชิ้น)', value: 'total'},
        {text: 'ราคา(บาท)', value: 'total'},
        {text: 'ราคารวม(บาท)', value: 'total'},
        {text: 'เครื่องมือ', value: 'status'},
      ],
    }
  },
  methods: {}
}
</script>

<style scoped>
</style>
