<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      sm="12"
    >
      <v-card>
        <v-card-text>
          <!--          v-model="TextCopy"-->
          <v-textarea
            id="stepCheck"
            v-model="stepCheck"
            label="ขั้นตอนการเช็คสถานะสินค้า"
          >
            {{ stepCheck }}
          </v-textarea>

          <v-btn
            color="primary"
            @click="copy(1)"
          >
            คัดลอดข้อความ
          </v-btn>

          <v-text-field
            id="url_track"
            v-model="url_tracking.url_tracking"
            label="Web ภายนอก:"
          ></v-text-field>

          <v-btn
            color="primary"
            @click="copy(2)"
          >
            คัดลอดข้อความ
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6"
      sm="12"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                color="primary"
                class="warning-badge full-width"
                @click="upSlip"
              >
                แจ้งโอน
              </v-btn>
            </v-col>
            <v-col
              v-if="order.payment_type === 'installment'"
              style="padding: 0;margin:0;background-color: pink"
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                class="warning-badge full-width"
                @click="notiInstallment"
              >
                แจ้งผ่อนชำระ
              </v-btn>
            </v-col>
            <v-col
              v-if="!order.PD_emp_id"
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                class="warning-badge full-width"
                :disabled="order.order_status !== 'PP' && order.order_status !== 'PTP' && order.order_status !== 'F'"
                @click="confirmManufacturing"
              >
                <!--                {{ order.PP_tax_no ? 'แก้ไขเลขแทรค' : 'แจ้งเลขแทรค' }}-->
                ยืนยันการผลิต
              </v-btn>
            </v-col>
            <v-col
              v-if="order.PD_emp_id"
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                class="warning-badge full-width"
                :disabled="order.order_status !== 'PP' && order.order_status !== 'PTP' && order.order_status !== 'F'"
                @click="notiTax"
              >
                {{ order.PP_tax_no ? 'แก้ไขเลขแทรค' : 'แจ้งเลขแทรค' }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                class="warning-badge full-width"
                color="secondary"
                @click="printInvoice"
              >
                พิมพ์ใบสั่งซื้อ
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                class="warning-badge full-width"
                @click="printEnvelope"
              >
                พิมพ์ใบ ปณ
              </v-btn>
            </v-col>
            <v-col
              v-if="order.order_status != 'F' && ((order.PT_date != null || order.PT_date != '' || order.PT_type == 'DT' ))"
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                :disabled="order.order_status != 'PC' && order.PT_type != 'DP'"
                class="success-badge full-width"
                @click="uploadOrderConfirm"
              >
                ยืนยันการสั่งซื้อ
              </v-btn>
            </v-col>
            <v-col
              v-if="order.order_status != 'F'"
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                :disabled="order.order_status === 'F'"
                class="danger-badge full-width"
                @click="uploadOrderCancel"
              >
                ยกเลิกการสั่งซื้อ
              </v-btn>
            </v-col>
            <v-col
              v-if="order.order_status != 'F' && order.order_status === 'PTP' && order.PP_tax_no && !order.PTP_date "
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                :disabled="order.order_status !== 'PTP' || order.PTP_date"
                class="danger-badge full-width"
                @click="updatePrepareToShip"
              >
                เตรียมจัดส่ง
              </v-btn>
            </v-col>
            <v-col
              v-if="order.order_status != 'F' && order.order_status === 'PTP' && order.PP_tax_no && order.PTP_date "
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                :disabled="order.order_status === 'PTP' && !order.PTP_date"
                class="danger-badge full-width"
                @click="finishShipping"
              >
                จัดส่งสำเร็จ
              </v-btn>
            </v-col>
            <v-col
              v-if="order.order_status != 'PT' && order.order_status === 'PC'"
              cols="12"
              md="4"
              sm="6"
            >
              <v-btn
                :disabled="order.order_status === 'PTP' && !$order.PTP_date"
                class="warning-badge full-width"
                @click="billProblem"
              >
                แจ้งบิลมีปัญหา
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="dialogSlip"
        max-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <div class="d-flex justify-space-between"> -->
          <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
          <!-- </div> -->
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">อัพโหลดหลักฐานการโอนชำระการสั่งซื้อ</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-file-input
                    v-model="order.PT_slip"
                    label="ไฟล์"
                  ></v-file-input>

                  <v-text-field
                    v-model="order.PT_price_amt"
                    class="form-control"
                    type="number"
                    min="1"
                    placeholder="จำนวนเงิน"
                  ></v-text-field>
                  <!--                  @change="uploadFile($event)"-->
                </v-col>
                <v-col
                  v-if="order.PT_type == 'T'"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <p class="mb-0">
                    โอนเข้าบัญชี
                  </p>
                  <v-select
                    v-model="order.PT_BankID"
                    dense
                    outlined
                    hide-details
                    label="เลือกธนาคาร"
                    :items="bankList"
                    item-text="BankName"
                    item-value="BankID"
                    class="mt-2"
                  ></v-select>
                  <!--                  <div v-if="order.bank">-->
                  <!-- เลขบัญชี -->
                  <!--                    <p class="mb-2 mt-3">เลขที่บัญชี: {{ order.bank.account_number }}</p>-->
                  <!-- ชื่อบัญชี -->
                  <!--                    <p class="mb-2">ชื่อบัญชี: <span> {{ order.bank.account_name }}</span>-->
                  <!--                    </p>-->
                  <!-- เริ่มวันที่โอน -->
                  <!-- <p class="mb-2">
                    วันที่โอน:
                  </p> -->
                  <!--            <v-menu ref="menu1" :close-on-content-click="false" transition="scale-transition" offset-y hide-details-->
                  <!--              max-width="290px" min-width="auto">-->
                  <!--              <template v-slot:activator="{ on, attrs }">-->
                  <!--                <v-text-field v-model="payment.PT_date" label="Date" hint="MM/DD/YYYY format" persistent-hint-->
                  <!--                  :rules="[v => !!v || 'กรุณาเลือกวันที่โอน']" required v-bind="attrs" outlined dense-->
                  <!--                  @blur="date = parseDate(dateFormatted)" v-on="on"></v-text-field>-->
                  <!--              </template>-->
                  <!--              <v-date-picker-->

                  <!--                no-title @input="menu1 = false">-->
                  <!--              </v-date-picker>-->
                  <!--              <DatePicker-->
                  <!--                value-type="format"-->
                  <!--                format="YYYY-MM-DD"-->
                  <!--                label="วันที่โอน"-->
                  <!--                v-model="$store.state.orderDetil.PT_date"-->
                  <!--              ></DatePicker>-->
                  <!--            </v-menu>-->

                  <v-text-field
                    v-model="order.PT_date"
                    label="วันที่โอน"
                    type="date"
                    outlined
                    dense
                    hide-details
                    :rules="[v => !!v || 'กรุณากรอกวันที่โอน']"
                    required
                    placeholder=""
                    class="mb-3 mt-3"
                  >
                  </v-text-field>
                  <!--                    <DatePicker-->
                  <!--                      class="mb-3 mt-3"-->
                  <!--                      v-model="order.PT_date"-->
                  <!--                      style="width: 100%"-->
                  <!--                      placeholder="กรุณาเลือกวันที่โอน"-->
                  <!--                      value-type="format"-->
                  <!--                      format="DD-MM-YYYY"-->
                  <!--                      label="Date"-->
                  <!--                      title="วันที่โอน"-->
                  <!--                    ></DatePicker>-->
                  <!-- จบวันที่โอน -->

                  <!-- เวลาโอน -->
                  <!-- <p class="mb-2">เวลาที่โอน:</p> -->
                  <v-text-field
                    v-model="order.PT_time"
                    label="เวลาที่โอน"
                    type="time"
                    outlined
                    dense
                    hide-details
                    :rules="[v => !!v || 'กรุณากรอกเวลาที่โอน']"
                    required
                    placeholder=""
                    class="mb-2"
                  >
                  </v-text-field>
                  <!-- จบเวลาโอน -->

                  <!-- ยอดโอน -->
                  <!-- <p class="mb-2">ยอดโอน:</p> -->
                  <!--                    <v-text-field label="ยอดโอน" v-model="$store.state.orderDetil.PT_price_amt" type="number" outlined dense-->
                  <!--                                  :rules="[v => !!v || 'กรุณากรอกยอดที่โอน']" required hide-details placeholder="">-->
                  <!--                    </v-text-field>-->

                  <!--                    <v-file-input v-model="order.file_slip" show-size label="แนบสลิป">-->
                  <!--                    </v-file-input>-->
                  <!--                    <p v-if="order.PT_slip"><a :href="$store.state.orderDetil.PT_slip" target="_blank">กดดูไฟล์สลิป</a></p>-->

                  <!--                  </div>-->
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4 style="color: red">
                    ยอด: {{ order.order_expense }}
                    <!--                    {{ parseFloat((parseInt(sub_total() + total_importune() + totalConfigs()) + parseInt(order.transport_expense_price) - parseInt(order.order_price_discount))).toFixed(2) }}-->
                    บาท
                  </h4>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="close"
            >
              ยกเลิก
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="saveUploadSlip"
            >
              อัพโหลดหลักฐาน
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogTax"
        max-width="500px"
      >
        <!--        <template v-slot:activator="{ on, attrs }">-->
        <!-- <div class="d-flex justify-space-between"> -->
        <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
        <!-- </div> -->
        <!--        </template>-->
        <v-card>
          <v-card-title>
            <span class="text-h5">แจ้งเลขแทรค</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="order.PP_tax_no"
                    type="text"
                    placeholder="กรุณากรอกแจ้งเลขแทรค"
                    label="กรุณากรอกแจ้งเลขแทรค"
                  ></v-text-field>

                  <!--                  @change="uploadFile($event)"-->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="close"
            >
              ยกเลิก
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="updateStatusTrackingNumber"
            >
              ยืนยัน
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogInvoice"
        max-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <div class="d-flex justify-space-between"> -->
          <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
          <!-- </div> -->
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">พิมพ์ใบสั่งซื้อ</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-radio-group
                    v-model="show_img"
                    label="สถานะโชว์รูปสินค้า"
                  >
                    <v-radio
                      label="โชว์รูปสินค้า"
                      value="Y"
                    ></v-radio>
                    <v-radio
                      label="ไม่โชว์รูปสินค้า"
                      value="N"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>ระบุขนาดรูปสินค้า</h4>
                  <v-text-field
                    v-model="size"
                    type="text"
                    placeholder="ขนาด"
                    label="ขนาด"
                  ></v-text-field>
                  <!--                  <v-text-field-->
                  <!--                    type="text"-->
                  <!--                    placeholder="กรุณากรอกแจ้งเลขแทรค"-->
                  <!--                    v-model="height"-->
                  <!--                    label="สูง"-->
                  <!--                  ></v-text-field>-->
                  <!--                  @change="uploadFile($event)"-->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="close"
            >
              ยกเลิก
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="printConfirm(1)"
            >
              พิมพ์
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogEnvelope"
        max-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <div class="d-flex justify-space-between"> -->
          <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
          <!-- </div> -->
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">พิมพ์บิลส่ง ปณ</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-radio-group v-model="show_img">
                    <v-radio
                      label="โชว์รูปสินค้า"
                      value="Y"
                    ></v-radio>
                    <v-radio
                      label="ไม่โชว์รูปสินค้า"
                      value="N"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <h4>ระบุขนาดรูปสินค้า</h4>
                  <v-text-field
                    v-model="size"
                    type="text"
                    placeholder="ขนาด"
                    label="ขนาด"
                  ></v-text-field>
                  <!--                  <v-text-field-->
                  <!--                    type="text"-->
                  <!--                    placeholder="สูง"-->
                  <!--                    v-model="height"-->
                  <!--                    label="สูง"-->
                  <!--                  ></v-text-field>-->
                  <!--                  @change="uploadFile($event)"-->
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="close"
            >
              ยกเลิก
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="printConfirm(2)"
            >
              พิมพ์
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFinished"
        max-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <div class="d-flex justify-space-between"> -->
          <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->

          <!-- </div> -->
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">จัดส่งสำเร็จ</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="order.order_inv"
                    label="เลขที่การสั่งซื้อสินค้า"
                    type="text"
                    placeholder="เลขที่"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="close"
            >
              ยกเลิก
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="updateFinish"
            >
              ยืนยััน
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogProblem"
        max-width="500px"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <div class="d-flex justify-space-between"> -->
          <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
          <!-- </div> -->
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">แจ้งบิลมีปัญหา</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    v-model="order.bill_problem"
                    placeholder="แจ้งบิลมีปัญหา"
                    label="แจ้งบิลมีปัญหา"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="close"
            >
              ยกเลิก
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="confirmBillProblem"
            >
              แจ้งบิลมีปัญหา
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCartMinus, mdiMagnify, mdiTruck, mdiPrinterSearch,
} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import Swal from 'sweetalert2'
import instance_order from '@/services/order'
import instance_config from '@/services/config'
import bankAPI from '@/services/bank'
import user from '@/services/user'
import orderAPI from '@/services/order'

export default {
  components: { DatePicker },
  props: {
    order_id: { type: [String, Number] },
    order: { type: Object },
    order_details: { type: Array },
    url_tracking: { type: Object },
  },
  setup() {
    return {
      icons: {
        mdiCartMinus,
        mdiMagnify,
        mdiTruck,
        mdiPrinterSearch,
      },
      print,
    }
  },
  data() {
    return {
      stepCheck: 'ขั้นตอนหลังการโอนเงินเสร็จแล้ว..\n'
        + '1. ลิงค์ด้านบนที่ร้านส่งไป ลูกค้าเข้าไปเช็ครายละเอียดความถูกต้องของชื่อรุ่นโทรศัพท์ และลายที่ได้สั่งนะค้าบ\n'
        + '2. รอรับของจากขนส่งได้เลยครับใน 2-4 วันทำการ\n'
        + '3. หากสินค้ายังไม่ได้รับเกิน 1 อาทิตย์ ให้เช็คที่ตู้ไปรษณีย์ของตัวเองจะมีใบรับพัสดุทิ้งไว้ ให้ไปรับก่อน 14 วันทำการไม่งั้นของจะตีกลับมาหาทางร้านนะครับ\n'
        + '\n'
        + 'ลิงค์จะเปลี่ยนเป็นจัดส่งตอนไหน?..\n'
        + '1. เมื่อร้านได้รับยอดโอนเงินแล้ว 1-2 วันลิงค์จะเปลี่ยนเป็นจัดส่งของ\n'
        + '2. เมื่อเปลี่ยนเป็นจัดส่งแล้วจะมีเลขพัสดุขึ้น ' + "this.order.PP_tax_no ? this.order.PP_tax_no : 'ตัวอย่าง.. EV XXXXXXXX TH'" + '\n'
        + '3. ลูกค้าเอาเลขที่ได้รับไปกรอก-เช็คในเว็บไซต์\'\n'
        + '\'' + "{{$order->trackingUrl($order->transport_type_id)->url ? $order->trackingUrl($order->transport_type_id)->url : ''}}" + '\n'
        + '4. ลูกค้าจะทราบสถานะของว่าสินค้าอยู่ที่ไหน ขั้นตอนอะไร ของส่งหรือยังไม่ส่งจะมีแจ้งไว้หมดครับ\n'
        + '5. หลังจากนั้น..ไปรษณีย์ไทยจะนำจ่ายพัสดุสินค้าให้ลูกค้าถึงหน้าบ้านครับ\n'
        + '\n'
        + 'เมื่อได้รับของแล้ว..\n'
        + '1. ลูกค้าเช็คว่าใส่ได้ใส่ไม่ได้ทันที สินค้าจะเคลมได้ภายใน 7-14 วันตามปัญหาที่พบกับตัวเคส\n'
        + '2. เพื่อกำลังใจในการผลิตเคสของพี่ต่อไป รบกวนลูกค้ารีวิวกลับมาเป็นน้ำใจดีๆ ให้กับพี่ด้วยนะค้าบผม ขอบคุณมากๆ เลยค้าบ\'\n',
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'numberBill',
        },
        { text: 'รูป/ดาวน์โหลด', value: 'timeBill' },
        { text: 'รหัสลาย', value: 'name' },
        { text: 'ชื่อลาย', value: 'total' },
        { text: 'ยี่ห้อสินค้า', value: 'total' },
        { text: 'รุ่นสินค้า', value: 'total' },
        { text: 'จำนวน(ชิ้น)', value: 'total' },
        { text: 'ราคา(บาท)', value: 'total' },
        { text: 'ราคารวม(บาท)', value: 'total' },
        { text: 'เครื่องมือ', value: 'status' },
      ],
      dialogSlip: false,
      dialogTax: false,
      dialogInvoice: false,
      dialogEnvelope: false,
      dialogFinished: false,
      dialogProblem: false,
      bankList: [],
      configs: [],
      show_img: 'Y',
      width: 100,
      height: 150,
      size: 100,
      file: null,
    }
  },
  mounted() {
    this.getBank()
    this.getConfig()
  },
  methods: {
    getBankShow() {
      // this.payment.PT_BankID
      const { PT_BankID } = this.$store.state.orderDetil
      const $this = this

      const data = {
        order_id: this.$store.state.orderDetil.order_id,
        PT_BankID,
      }

      orderAPI.orderUpdatePayment(data).then(res => {
        console.log(res, 111)
        if (res.status === 200) {
          console.log(this.$store.state.orderData, 12354)
          this.getOrderDetail()
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
        }

        // removrToken
        user.removrToken(res)
      })
        .catch(err => {
          console.log(err, 4444)

          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })

      // if (PT_BankID) {
      //   bankAPI
      //     .show(PT_BankID)
      //     .then(res => {
      //       console.log(res,344)
      //       if (res.status === 200) {
      //         $this.order_detail.bank = res.data
      //         console.log($this.order_detail.bank)
      //       }
      //
      //       // removrToken
      //       user.removrToken(res)
      //     })
      //     .catch(error => {
      //       console.log(error,1234)
      //     })
      // }
      //
      // console.log(this.payment,3333)
    },
    getBank() {
      bankAPI
        .get()
        .then(res => {
          if (res.status === 200) {
            this.bankList = res.data
          }

          // removrToken
          user.removrToken(res)
        })
        .catch(error => {
        })
    },
    getConfig() {
      const data = {
        order_id: this.order_id,
      }
      this.$store.state.isLoading = true
      instance_config.getConfigOrder(data).then(res => {
        this.configs = res.data.data
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    totalConfigs() {
      const total = []
      Object.entries(this.configs).forEach(([key, val]) => {
        if (val.check && val.id != 2) {
          if (this.amount() < 6) {
            total.push(parseInt(this.amount()) * parseInt(val.value))
            val.value = this.amount() * 40
          } else if (this.amount() > 5 && this.amount() < 11) {
            val.value = this.amount() * 35
            total.push(this.amount() * 35)
          } else {
            total.push(this.amount() * 30)
            val.value = this.amount() * 30
          }
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    amount() {
      const total = []
      Object.entries(this.order_details).forEach(([key, val]) => {
        if (val.product_amount) {
          total.push(parseInt(val.product_amount))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    total_importune() {
      const total = []
      total.push(0)
      if (this.importune == 'Y') {
        if (this.amount < 13) {
          total.push(this.amount * 20)
        } else if (this.amount > 12 && this.amount < 50) {
          total.push(this.amount * 15)
        } else {
          total.push(this.amount * 10)
        }
      } else {
        total.push(0)
      }

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    sub_total() {
      const total = []
      Object.entries(this.order_details).forEach(([key, val]) => {
        if (val.product_amount && val.product_price) {
          total.push(parseInt(val.product_amount) * parseInt(val.product_price))
        } else {
          total.push(0)
        }
      })

      return total.reduce((total, num) => parseInt(total) + parseInt(num), 0)
    },
    upSlip() {
      this.order.PT_slip = null
      this.order.PT_price_amt = null
      this.order.PT_BankID = null
      this.order.PT_time = null
      this.dialogSlip = true
    },
    confirmManufacturing() {
      Swal.fire({
        title: 'ยืนยันการผลิตใช่หรือไม่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            this.$store.state.isLoading = true
            const data = {
              order_id: this.order_id,
            }
            instance_order.confirmManufacturing(data).then(res => {
              this.$store.state.isLoading = false
              if (res.data.success) {
                Swal.fire({
                  icon: 'success',
                  title: res.data.message,
                  showConfirmButton: false,
                  timer: 3000,
                })
                location.reload()
              } else {
                Swal.fire({
                  icon: 'error',
                  title: res.data.message,
                  showConfirmButton: false,
                  timer: 3000,
                })
              }
            }).catch(err => {
              this.$store.state.isLoading = false
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด !!',
                timer: 1500,
                text: err.data.message,
              })
            })
          }
        })
    },
    notiTax() {
      this.dialogTax = true
    },
    finishShipping() {
      this.dialogFinished = true

      // {url('admin/update_finish').'/'.$order->order_id
    },
    billProblem() {
      this.dialogProblem = true

      // action="{{url('admin/bill_problem').'/'.$order->order_id}}"
    },
    close() {
      this.dialogSlip = false
      this.dialogTax = false
      this.dialogFinished = false
      this.dialogProblem = false
      this.dialogInvoice = false
      this.dialogEnvelope = false
    },

    // end modal
    saveUploadSlip() {
      if (this.order.PT_type === 'DP' && this.order.PT_price_amt > this.order.order_expense - (this.order.order_expense - this.order.amount_deposit)) {
        Swal.fire({
          text: 'จำนวนเงินไม่ถูกต้อง',
          timer: 1500,
          icon: 'error',
          showConfirmButton: false,
          button: false,
        })

        return false
        this.check_error = false
      }

      // if ('{{$order->PT_type}}' === 'DP' && parseFloat(this.order.PT_price_amt) > parseFloat('{{($order->order_expense-$order->amount_deposit)}}')) {

      // if (!this.order.PT_slip) {
      //   this.validate_error.slip = false
      // } else {
      //   this.validate_error.slip = true
      //   this.check_error = true;
      // }
      //
      // if (!this.order.price_amt) {
      //   this.validate_error.price_amt = false
      // } else {
      //   this.validate_error.price_amt = true
      //   this.check_error = true;
      // }
      //
      // if (!this.order.PT_price_amt || !this.order.PT_slip) {
      //   this.check_error = false;
      // }
      //
      //
      // if (!this.check_error) {
      //   return false
      // }

      if (!this.check_error) {
        const data = {
          order_id: this.order_id,
          file: this.order.PT_slip,
          PT_price_amt: this.order.PT_price_amt,
          PT_BankID: this.order.PT_BankID,
          PT_date: this.order.PT_date,
          PT_time: this.order.PT_time,
          user_id: this.order.member_id,
        }

        instance_order.uploadSlip(data).then(res => {
          this.$store.state.isLoading = false
          if (res.data.success) {
            Swal.fire({
              text: 'อัพโหลดหลักฐานสำเร็จ',
              timer: 3000,
              icon: 'success',
              showConfirmButton: false,
              button: false,
            })
            this.close()
            location.reload()
          } else {
            Swal.fire({
              icon: 'error',
              title: 'เกิดข้อผิดพลาด !!',
              timer: 3000,
              text: res.data.message,
            })
          }
        }).catch(err => {
          this.$store.state.isLoading = false
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.data.message,
          })
        })
      }
    },
    notiInstallment() {
      const data = {
        order_id: this.order_id,
      }
      this.$store.state.data = true
      location.reload()
      instance_order.orderInstallment(data).then(res => {
        this.$store.state.data = false
        location.reload()
      }).catch(err => {
        this.$store.state.data = false
      })
    },
    updateStatusTrackingNumber() {
      const data = {
        order_id: this.order_id,
        PP_tax_no: this.order.PP_tax_no,
      }
      this.$store.state.data = true
      instance_order.orderTax(data).then(res => {
        if (res.data.success) {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
          location.reload()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: res.data.message,
          })
        }
        this.$store.state.data = false

        // location.reload()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.data.message,
        })
        this.$store.state.data = false
      })
    },
    printInvoice() {
      this.width = 100
      this.height = 150
      this.show_img = 'Y'
      this.dialogInvoice = true

      // {{url('admin/print_invoice/').'/'.$order->order_id}}
    },
    printEnvelope() {
      this.width = 100
      this.height = 150
      this.show_img = 'Y'
      this.dialogEnvelope = true

      // this.$store.state.data = true;
      // instance_order.orderEnvelope().then(res=>{
      //
      //   this.$store.state.data = false;
      // }).catch(err => {
      //
      //   this.$store.state.data = false;
      // })
    },
    printConfirm(type) {
      let url = 'print_invoice'
      if (type == 1) {
        url = '/print_invoice'
      } else {
        url = '/print_envelope'
      }
      url += `/${this.order_id}?show=${this.show_img}&size=${this.size}`
      window.open(url, '_blank', 'noreferrer')
    },
    updateFinish(type) {
      const data = {
        order_id: this.order_id,
      }

      instance_order.updateFinish(data).then(res => {
        this.$store.state.isLoading = false
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        })
        location.reload()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          timer: 1500,
          text: err.data.message,
        })
        this.$store.state.isLoading = false
      })

      // action="{{url('order/update_finish').'/'.$order->order_id}}"
    },
    uploadOrderConfirm() {
      // if(this.order.PT_price_amt < (parseFloat((parseInt(this.sub_total() + this.total_importune() + this.totalConfigs()) + parseInt(this.order.transport_expense_price) - parseInt(this.order.order_price_discount))).toFixed(2))){
      if (this.order.PT_type != 'CC') {
        console.log(this.order.amount_deposit, 789, this.order.order_expense)
        if (parseFloat(this.order.amount_deposit) < parseFloat(this.order.order_expense)) {
          alert('จำนวนเงินน้อยกว่ายอดสั่งซื้อ')

          return false
        }
      }

      Swal.fire({
        title: 'ยืนยันการสั่งซื้อ ใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            this.$store.state.isLoading = true
            const data = {
              order_id: this.order_id,
            }
            instance_order.orderConfirm(data).then(res => {
              this.$store.state.isLoading = false
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              })
              location.reload()
            }).catch(err => {
              this.$store.state.isLoading = false
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด !!',
                timer: 1500,
                text: err.data.message,
              })
            })
          }
        })
    },
    uploadOrderCancel() {
      Swal.fire({
        title: 'ยกเลิกการสั่งซื้อ ใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            this.$store.state.isLoading = true
            const data = {
              order_id: this.order_id,
            }

            instance_order.orderCancel(data).then(res => {
              this.$store.state.isLoading = false
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                timer: 1500,
              })
              location.reload()
            }).catch(err => {
              this.$store.state.isLoading = false
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด !!',
                timer: 1500,
                text: err.data.message,
              })
            })
          }
        })
    },
    updatePrepareToShip() {
      Swal.fire({
        title: 'เตรียมการจัดส่ง ใช่หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      })
        .then(willDelete => {
          if (willDelete.isConfirmed) {
            const data = {
              order_id: this.order_id,
            }
            this.$store.state.data = true
            instance_order.prepareToShip(data).then(res => {
              this.$store.state.data = false
              location.reload()
            }).catch(err => {
              this.$store.state.data = false
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด !!',
                timer: 1500,
                text: err.data.message,
              })
            })
          }
        })
    },
    confirmBillProblem() {
      const data = {
        order_id: this.order_id,
        bill_problem: this.order.bill_problem,
      }
      this.$store.state.data = true
      instance_order.orderProblem(data).then(res => {
        this.$store.state.data = false
        Swal.fire({
          icon: 'success',
          timer: 1500,
          title: res.data.message,
        })
        location.reload()
      }).catch(err => {
        this.$store.state.data = false
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          timer: 1500,
          text: err.data.message,
        })
      })
    },
    copy(type) {
      let copyText = ''
      if (type == 1) {
        copyText = document.getElementById('stepCheck')
      } else {
        copyText = document.getElementById('url_track')
      }

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy')

      /* Alert the copied text */
      alert(`คัดลอกลิ้งค์: ${copyText.value}`)
    },
  },
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
